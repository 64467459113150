const departments = {
    pharmacy: {
        en: 'Pharmacy department',
        ar: 'قسم الصيدلية'
    },
    doctors: {
        en: 'Doctors department',
        ar: 'قسم الاطباء'
    },
    lab: {
        en: 'Lab department',
        ar: 'قسم المختبر',
    },
    nurse: {
        en: 'Nurse department',
        ar: 'قسم التمريض'
    },
    dental: {
        en: 'Dental department',
        ar: 'قسم الأسنان'
    },
    reception: {
        en: 'Reception',
        ar: 'الاستقبال'
    },
    ncd: {
        en: 'Diabetes & NCD Section',
        ar: 'قسم السكر والأمراض المزمنة'
    },
    mental: {
        en: 'Mental Health Section',
        ar: 'قسم الصحة النفسية'
    },
    geriatric: {
        en: 'Geriatric Section',
        ar: 'قسم كبار السن'
    }
}

export default departments;