import React from 'react';
// import "survey-react/modern.css";
import 'survey-core/modern.min.css';


const styles = {
    default: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        height: '80vh',
        alignItems: 'center'
    }
}
const Language = ({ setLang }) => {
    return (
        <div style={styles.default}>
            <div className="col-md-4">
                <img alt="phc logo" src="https://register.cdphc.com/assets/img/cdphc-logo.png" className="logo img-fluid" />
            </div>
            <br /><br />
            <span>Please choose your language:</span><br />
            <span>يرجى اختيار اللغة:</span>
            <br />
            <br />
            <br />
            <button className="sv-btn sv-btn--navigation sv-footer__next-btn" onClick={() => setLang('ar')}>عربي</button>
            <br />
            <button className="sv-btn sv-btn--navigation sv-footer__next-btn" onClick={() => setLang('en')}>English</button>
        </div>
    );

}

export default Language;
