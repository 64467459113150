import React, { useState } from 'react';
import { Spin, Alert } from 'antd';

const withLoader = (WrappedComponent) => () => {
    const [loading,  setLoading] = useState(true);
    const [failed, setFailed] = useState(false);

    const returnFailedOrComponent = () => {
        if (failed) {
            return (
                <div className="col-md-6 offset-md-3">
                    <br /><br /><br />
                    <Alert message="Failed to connect, please try again" type="error" /> 
                </div>
                
            );
        }
        else {
            return (
            <Spin spinning={loading} size="large">
                <WrappedComponent setLoading={setLoading} setFailed={setFailed} />
            </Spin>
            );
        }
    }
    return returnFailedOrComponent();
}

export default withLoader;