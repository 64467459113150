import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Language from '../../pages/language'
import settings from '../../config/settings';
import departments from '../../config/departments';
import withLoader from '../../hoc/with-Loader';
import Cookies from 'js-cookie';
import 'survey-core/modern.min.css';
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

StylesManager.applyTheme("modern");

const SurveyPage = ({setLoading, setFailed}) => {
    const [lang, setLang] = useState(null);
    const [ json, setJson ] = useState({});
    const [isClosed, setIsClosed] = useState(false);

    const { centerId, department } = useParams();
    const history = useHistory()

    useEffect(() => {
        const fetch = async () => {
            try {
              setLoading(true);
              const res = await axios.get(`${settings.baseURL}/survey/${centerId}/${department}`)
              console.log(res.data);
              setLoading(false);
              setFailed(false);
              
              if (res.data.isClosed) 
                return setIsClosed(true)

              res.data.json.pages[1].elements[0].html.default = res.data.name;
              res.data.json.pages[1].elements[0].html.ar = res.data.ar;
              
              if (department in departments) {
                res.data.json.pages[1].elements[1].html.default = departments[department].en;
                res.data.json.pages[1].elements[1].html.ar = departments[department].ar;

                setJson(res.data.json);
              }
              else {
                throw new Error("department is not there");
              }

            }
            catch (e) {
                console.log("could not fetch data!", e);
                setFailed(true);
                // to do , go to error
            }
        }

        fetch();
        
    },[])

    if (isClosed) {
      //return <h1>This survey is closed right now, please try again later</h1>
      return <Redirect to='/closed' />
    }

    const showLangOrCookie = () => {
      const surveyDone = Cookies.get(department);

      if (surveyDone) {
        return (<Redirect to='/success' />);
      }
      else {
        return <Language setLang={setLang} />
      }
    }
    const showLanguageOrSurvey = () => {
      if (lang) {
        const survey = new Model(json)
        survey.locale = lang;
        survey.showPrevButton = false;
        survey.pageNextText = lang === 'en' ? 'Next' : 'التالي';
        survey.completeText = lang === 'en' ? 'Complete' : 'إرسال';

        survey.onComplete.add( async (s) => {
          try {
            await axios.post(`${settings.baseURL}/survey/${centerId}/${department}`, s.data);
            Cookies.set(department, true, { expires: 90, path: '' });
            //history.push('/success');         
          }
          catch(e)  {
            console.log(e);
            survey.completedHtml = "Failed. please try again";
            history.push('/error');
          }
        } )
        return (
          <div className={lang === 'en' ? 'App' : 'App-RTL'}>
            <div className="col-md-4">
              <img alt="phc logo" src="https://register.cdphc.com/assets/img/cdphc-logo.png" className="logo img-fluid" />
            </div>
            
            <Survey model={survey} />

          </div>
        );
      }
      else {
          return showLangOrCookie();
      }
    }
  
    return showLanguageOrSurvey();
}

export default withLoader(SurveyPage);