import React from 'react';

const ErrorPage = () => {
    return (<div class="container">
        <div class="col-md-6 offset-md-3 text-center">
            <i class="fas fa-times fa-7x"></i><br /><br />
            <h3 class="alert alert-danger">Please try again</h3>
        </div>
        
    </div>);
}

export default ErrorPage;