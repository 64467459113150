import React from 'react';

const ClosedPage = () => {
    return (<div class="container">
        <div class="col-md-6 offset-md-3 text-center">
            <i class="fas fa-times fa-7x"></i><br /><br />
            <h3 class="alert alert-danger">This survey is closed right now, please try again later</h3>
            <h3 class="alert alert-danger">هذا الاستبيان مغلق حاليا، يرجى المحاولة وقت لاحق</h3>
        </div>
        
    </div>);
}

export default ClosedPage;