import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import SurveyPage from './survey/pages/surveyPage';
import SuccessPage from './survey/pages/successPage';
import ErrorPage from './survey/pages/errorPage';
import ClosedPage from './survey/pages/closedPage';

function App() {
  return (
    <BrowserRouter>
        <Route path="/:centerId/:department">
          <SurveyPage />
        </Route>
        <Route path="/success">
          <SuccessPage />
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/closed">
          <ClosedPage />
        </Route>
        <Route exact path="/">
          <ErrorPage />
        </Route>
    </BrowserRouter>
  )
}

export default App;
